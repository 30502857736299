import React from "react"
import { DescriptionText, InfoContainer, SectionTitle } from "./styles";
import { OutlineButton } from "../../../../../../components/OutlineButton";
import { MainButton } from "../../../../../../components/MainButton";
import { GUEST_ERROR_WHATSAPP_URL } from "../../../../../../constants/ContactUrls";

interface GuestNotFoundProps {
    onReset: () => void;
}

export const GuestNotFound = ({onReset}: GuestNotFoundProps) => {

    return (
        <InfoContainer>
            <SectionTitle>NENHUM CONVIDADO ENCONTRADO!</SectionTitle>
                <DescriptionText>Nenhum convidado foi encontrado com o nome digitado. Verifique se o nome está correto e tente novamente.</DescriptionText>
                <OutlineButton secondary onClick={onReset}>NOVA BUSCA</OutlineButton>
                <MainButton secondary onClick={() => window.location.href = GUEST_ERROR_WHATSAPP_URL}>Falar com os noivos</MainButton>
        </InfoContainer>
    )
}