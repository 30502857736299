
import React from "react";
import { CheckBoxContainer, CheckBoxInput } from "./styles";

interface CheckBoxProps {
    checked: boolean;
    onChange: () => void;
    label: string;
}

export const CheckBox = ({ checked, onChange, label }: CheckBoxProps) => {
    return (
        <CheckBoxContainer>
            <CheckBoxInput type="checkbox" checked={checked} onChange={onChange} />
            <label>{label}</label>
        </CheckBoxContainer>
    );
}