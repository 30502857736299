import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.secondaryColor};
    width: 90%;
    transition: all 0.2s;

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        scale: 1.02;
    }
`;

export const StyledGuestName = styled.h1`
    ${({ theme }) => theme.typography.secondaryTitle};
    font-size: 1.4rem;
    margin: 0;
    text-align: center;
`;

export const InfoText = styled.p`
    ${({ theme }) => theme.typography.secondaryText};
    margin: 0;
    font-size: 0.7rem;
`;

export const FamilyText = styled.p`
    ${({ theme }) => theme.typography.secondaryTitle};
    margin: 0;
    font-size: 0.9rem;
`;

export const FamilyTextContainer = styled.div`
    display: flex;
    margin-top: 4px;
`;