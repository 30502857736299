import React from "react"
import { GuestsFamily } from "../../../../../../types/api/invitedFamily";
import { DescriptionText, InfoContainer, SectionTitle } from "./styles";
import MainInput from "../../../../../../components/MainInput";
import { OutlineButton } from "../../../../../../components/OutlineButton";
import { useSearchGuest } from "./hooks/useSearchGuest";
import ContentLoader from "react-content-loader";
import { useTheme } from "styled-components";

interface SearchGuestProps {
    onResult: (resultSearch: GuestsFamily[]) => void;
    onError: (error: string) => void;
}

export const SearchGuest = ({ onResult, onError }: SearchGuestProps) => {

    const { guestName, handleSearch, nameError, setGuestName, isLoading } = useSearchGuest({ onResult, onError });
    const currentTheme = useTheme();

    const LoadingContent = () => {
        return (
            <ContentLoader
                speed={1}
                width={300}
                height={150}
                viewBox="0 0 300 150"
                backgroundColor={currentTheme.palette.emptyGreen}
                foregroundColor={currentTheme.palette.imageMask}
            >
                <rect x="9" y="7" rx="3" ry="3" width="290" height="40" />
                <rect x="9" y="57" rx="3" ry="3" width="290" height="40" />
                <rect x="9" y="107" rx="3" ry="3" width="290" height="40" />
            </ContentLoader>
        );
    };

    return (
        <InfoContainer>
            <SectionTitle>CONFIRMAÇÃO DE PRESENÇA</SectionTitle>
            {
                isLoading ? <LoadingContent /> :

                    (
                        <>
                            <DescriptionText>Utilize o código QR do convite para confirmar sua presença ou digite seu nome abaixo:</DescriptionText>
                            <MainInput
                                title="Nome como está no convite:"
                                placeholder="Fulano da Silva"
                                onChange={(newName) => setGuestName(newName)}
                                type="text"
                                value={guestName}
                                errorText={nameError}
                            />
                            <OutlineButton secondary onClick={handleSearch}>BUSCAR</OutlineButton>
                        </>
                    )
            }
        </InfoContainer>
    )
}