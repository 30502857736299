import styled from "styled-components";
import { DEVICE } from "../../../../constants/ScreenSizes";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.text};
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text};
`;

export const SectionTitle = styled.div`
    ${({ theme }) => theme.typography.primaryTitle};
    color: ${({ theme }) => theme.palette.tertiaryColorLight};
    text-align: center;
    margin-top: 50px;
    font-size: 1.5rem;
    margin-top: 100px;
`;

export const DescriptionText = styled.p<WidthProps>`
    ${({ theme }) => theme.typography.primaryText};
    color: ${({ theme }) => theme.palette.primaryColor};
    margin-top: 20px;
    text-align: center;
    max-width: 300px;
    line-height: 1.5;
    width: ${({ width }) => width + "px" || "100%"};

    @media ${DEVICE.DESKTOP} {
        font-size: 1.1rem;
        max-width: 500px;
        width: ${({ width }) => width ? + 300 + "px" : "100%"};
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    gap: 20px;
    margin-bottom: 50px;
`;


export interface WidthProps {
    width?: number;
}