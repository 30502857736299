import React from "react";
import { AlexaImage, ButtonContainer, ContainerBackground, InfosContainer } from "./styles";
import alexa from "../../../../assets/images/alexa.png";
import { OutlineButton } from "../../../../components/OutlineButton";
import { Header } from "../../../../components/Header";
import { useNavigate } from "react-router-dom";
import { GENERAL_ROUTES } from "../../../../constants/routes";
import { MainButton } from "../../../../components/MainButton";


export const ConfirmationFeedback = () => {
    const navigate = useNavigate();

    return (
        <ContainerBackground>
            <Header />
            <h3>Agradecemos sua resposta</h3>
            <p>Sua resposta nos ajuda a organizar com detalhes esse dia especial!</p>
            {/* <RegressiveCounterContainer>
                <RegressiveCounter />
            </RegressiveCounterContainer> */}
            <InfosContainer>
                <h4>DETALHES DO EVENTO</h4>
                <p>19 de julho de 2025</p>
                <p>Paróquia São Pedro Apóstolo</p>
                <p>18:00</p>
                <AlexaImage src={alexa} alt="Alexa" />
                <br />
                <h4>Conheça nossa história e veja os detalhes do evento em nosso site!</h4>
                <ButtonContainer>
                    <MainButton onClick={() => navigate(GENERAL_ROUTES.HOME.route)}>Ir para o site</MainButton>
                    <OutlineButton onClick={() => navigate(GENERAL_ROUTES.GIFT_LIST.route)}>Lista de presentes</OutlineButton>
                </ButtonContainer>
            </InfosContainer>
        </ContainerBackground>
    );
}