import React, { useState } from "react";
import { ContainerBackground } from "./styles";
import { Header } from "../../../../components/Header";
import { GuestsFamily } from "../../../../types/api/invitedFamily";
import { SearchGuest } from "./components/SearchGuest";
import { GuestNotFound } from "./components/GuestNotFound";
import { GuestResultList } from "./components/GuestResultList";
import { useNavigate } from "react-router-dom";
import { GENERAL_ROUTES } from "../../../../constants/routes";

export const BlankRsvp = () => {

    const [guestResultList, setGuestResultList] = useState<GuestsFamily[] | null>(null);
    const navigate = useNavigate();

    const goToRsvpPage = (guest: GuestsFamily) => {
        navigate(GENERAL_ROUTES.RSVP.route + "/" + guest._id);
    }

    const getContent = () => {
        if (guestResultList === null) {
            return (
                <SearchGuest
                    onResult={(result) => setGuestResultList(result)}
                    onError={(error) => console.log(error)}
                />
            )
        } else if (guestResultList.length === 0) {
            return <GuestNotFound onReset={() => setGuestResultList(null)} />
        } else {
            return <GuestResultList
                resultList={guestResultList}
                onNewSearchRequested={() => setGuestResultList(null)}
                onSelected={(guest) => goToRsvpPage(guest)}
            />
        }
    }


    return (
        <ContainerBackground>
            <Header />
            {getContent()}
        </ContainerBackground>
    )
};