import React from "react"
import { GuestName } from "../../../../../../types/api/invitedFamily"
import { CheckBoxContainer, StyledCardBackground, StyledQuestionContainer } from "./styles";
import { CheckBox } from "../../../../../../components/CheckBox";

interface GuestCardProps {
    guestInfos: GuestName;
    onChangeGuestState: (value: GuestName) => void;
}

export const GuestCard = ({ guestInfos, onChangeGuestState }: GuestCardProps) => {

    const updateState = (status: "confirmed" | "declined") => {
        const updatedGuest = { ...guestInfos, status };
        onChangeGuestState(updatedGuest);
    }

    return (
        <StyledCardBackground>
            <h1>{guestInfos.name}</h1>
            <StyledQuestionContainer>
                <p>Comparecerá ao evento?</p>
                <CheckBoxContainer>
                    <CheckBox
                        checked={guestInfos.status === "confirmed"}
                        onChange={() => updateState(guestInfos.status === "confirmed" ? "declined" : "confirmed")}
                        label="Sim" />
                    <CheckBox
                        checked={guestInfos.status === "declined"}
                        onChange={() => updateState(guestInfos.status === "declined" ? "confirmed" : "declined")}
                        label="Não"
                    />
                </CheckBoxContainer>
            </StyledQuestionContainer>
        </StyledCardBackground>
    )
}