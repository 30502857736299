import styled from "styled-components";

export const StyledCardBackground = styled.div`
    background-color: ${(props) => props.theme.palette.primaryColor};
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    color: ${(props) => props.theme.palette.text};

    h1 {
        margin: 0;
        font-size: 1.5rem;
    }
`;

export const StyledQuestionContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    p {
        margin: 0;
        ${({ theme }) => theme.typography.lightText};
        color: ${({ theme }) => theme.palette.textSecondary};
    }
`;

export const CheckBoxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 120px;
`;