import { api } from ".";
import { GuestsFamily } from "../../types/api/invitedFamily";

export const getRsvpById = (id: string) => {
    return new Promise<GuestsFamily>((resolve, reject) => {
        api.get(`/familyInvitations?id=${id}`).then((response) => resolve(response.data)).catch((error) => reject(error));
    });
}

export const getRsvpByName = (name: string) => {
    return new Promise<GuestsFamily[]>((resolve, reject) => {
        api.get(`/familyInvitations?inviteName=${name}`).then((response) => resolve(response.data)).catch((error) => reject(error));
    });
}

export const getAllRsvps = () => {
    return new Promise<GuestsFamily[]>((resolve, reject) => {
        api.get("/familyInvitations").then((response) => resolve(response.data)).catch((error) => reject(error));
    });
}

export const updateRsvp = (rsvp: GuestsFamily) => {
    return new Promise<GuestsFamily>((resolve, reject) => {
        api.put("/familyInvitations?id=" + rsvp._id, {...rsvp, hasConfirmationUpdated: true}).then((response) => resolve(response.data)).catch((error) => reject(error));
    });
}