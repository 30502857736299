import styled from "styled-components";
import { DEVICE } from "../../../../../../constants/ScreenSizes";

export const SectionTitle = styled.div`
    ${({ theme }) => theme.typography.primaryTitle};
    text-align: center;
    margin-top: 50px;
    font-size: 1.5rem;
    margin-top: 100px;
`;

export const DescriptionText = styled.p<WidthProps>`
    ${({ theme }) => theme.typography.primaryText};
    margin-top: 20px;
    text-align: center;
    max-width: 300px;
    line-height: 1.5;
    width: ${({ width }) => width + "px" || "100%"};

    @media ${DEVICE.DESKTOP} {
        font-size: 1.1rem;
        max-width: 500px;
        width: ${({ width }) => width ? + 300 + "px" : "100%"};
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    gap: 20px;
`;

export interface WidthProps {
    width?: number;
}