import styled from "styled-components";

export const CheckBoxContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;

    label {
        font-size: 1rem;
        margin: 0;
        ${({ theme }) => theme.typography.lightText};
        color: ${({ theme }) => theme.palette.textSecondary};
    }
`;

export const CheckBoxInput = styled.input`
    appearance: none; /* Remove a aparência padrão */
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.text};
    cursor: pointer;
    position: relative;

    &:checked {
        background-color: ${({ theme }) => theme.palette.secondaryColor};
        border-color:${({ theme }) => theme.palette.secondaryColor};
    }

    &:checked::after {
        content: '';
        position: absolute;
        top: 40%;
        left: 50%;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: translate(-50%, -50%) rotate(45deg); /* Cria o símbolo de check */
    }
`;