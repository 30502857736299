import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GuestName, GuestsFamily } from "../../../../../types/api/invitedFamily";
import { GENERAL_ROUTES } from "../../../../../constants/routes";
import { getRsvpById, updateRsvp } from "../../../../../services/api/rsvp";
import toast from "react-hot-toast";

export const useRsvp = (id: string | undefined) => {
    const [currentRsvp, setCurrentRsvp] = useState<GuestsFamily | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            navigate(GENERAL_ROUTES.RSVP.route);
            return;
        }

        getRsvpById(id)
            .then((response) => setCurrentRsvp(response))
            .catch(() => navigate(GENERAL_ROUTES.RSVP.route));
    }, [id, navigate]);

    const updateGuestNameState = (guest: GuestName) => {
        if (!currentRsvp) return;

        const updatedGuests = currentRsvp.names.map((guestInfo) =>
            guestInfo._id === guest._id ? guest : guestInfo
        );

        setCurrentRsvp({ ...currentRsvp, names: updatedGuests });
    };

    const confirmRsvp = async () => {
        if (!currentRsvp) return;

        if(currentRsvp.names.some((guest) => guest.status === "pending")) {
            toast.error("Por favor, confirme a presença de todos os convidados.");
            return;
        }

        try {
            toast.loading("Confirmando presença...");
            await updateRsvp(currentRsvp);
            toast.dismiss();
            navigate(GENERAL_ROUTES.RSVP_FEEDBACK.route);
        } catch (error) {
            console.error("Erro ao confirmar presença: ", error);
            toast.error("Erro ao confirmar presença. Tente novamente mais tarde.");
        }
    };

    return {
        currentRsvp,
        updateGuestNameState,
        confirmRsvp,
    };
};