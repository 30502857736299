import { useState } from "react";
import { getRsvpByName } from "../../../../../../../services/api/rsvp";
import { GuestsFamily } from "../../../../../../../types/api/invitedFamily";

interface SearchGuestHookProps {
    onResult: (result: GuestsFamily[]) => void;
    onError: (error: string) => void;
}

export const useSearchGuest = ({onResult, onError} : SearchGuestHookProps) => {
    const [guestName, setGuestName] = useState("");
    const [nameError, setNameError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSearch = () => {
        if(guestName === "") {
            setNameError("Parece que o nome não foi preenchido corretamente.");
            return;
        }else {
            setNameError("");
        }
        setIsLoading(true);
        getRsvpByName(guestName).then((response) => {
            onResult(response);
        }).catch((error) => {
            onError(error)
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return {
        guestName,
        setGuestName,
        nameError,
        handleSearch,
        isLoading
    }

}