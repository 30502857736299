import React from "react";
import { GuestsFamily } from "../../../../../../../../types/api/invitedFamily";
import { CardContainer, StyledGuestName } from "./styles";

interface GuestFamilyCardProps {
    guestInfos: GuestsFamily,
    onSelected: () => void;
}

export const GuestFamilyCard = ({ guestInfos, onSelected }: GuestFamilyCardProps) => {

    const formatGuestName = (guest: string) => {
        if(guest.length > 20) {
            return guest.slice(0, 20) + "...";
        }
        return guest;
    }

    // const formatFamilyMembers = (familyMembers: GuestName[]) => {
    //     let formattedFamilyMembers = "";
    //     familyMembers.forEach((member, index) => {
    //         formattedFamilyMembers += formatGuestName(member.name) + (index === familyMembers.length - 1 ? "." : ", ");
    //     });
        
    //     if(formattedFamilyMembers.length > 50) {
    //         return formattedFamilyMembers.slice(0, 50) + "...";
    //     }
    //     return formattedFamilyMembers;
    // }


    return (
        <CardContainer onClick={onSelected}>
            {/* <InfoText>Nome no convite: </InfoText> */}
            <StyledGuestName>{formatGuestName(guestInfos.inviteName)}</StyledGuestName>
            {/* <InfoText>Convidados:</InfoText>
            <FamilyTextContainer>
                <FamilyText>{formatFamilyMembers([...guestInfos.names])}</FamilyText>
            </FamilyTextContainer> */}
        </CardContainer>
    )
} 